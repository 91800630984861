import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { AspectRatio, useTheme } from "@chakra-ui/react"
import { Sparklines, SparklinesCurve } from "react-sparklines"

import { Link } from "../../../ui/Link"
import { Box } from "../../../ui/Box"
import {
  HeadingFive,
  Text,
  TextSM,
  TextXS,
  Kicker,
} from "../../../ui/Typography"
import { Badge } from "../../../ui/Badge"
import { Image } from "../../../ui/Image"
import { Stack } from "../../../ui/Stack"
import { IconWrapper } from "../../../ui/Icon"

const CardImage = props => {
  const { breakpoints } = useTheme()

  // Starting point to fix the issue of loading
  // improperly sized images in listings
  // See: https://github.com/datastory-org/monorepo/issues/1496
  // for further ideas on improving it
  return (
    <Box>
      <AspectRatio ratio={3 / 2}>
        <Image
          alt=""
          sizes={`(min-width: ${breakpoints.lg}) 25vw, (min-width: ${breakpoints.md}) 50vw, 100vw`}
          fill
          {...props}
        />
      </AspectRatio>
    </Box>
  )
}

const componentMap = { HeadingFive, Text, TextSM, Kicker, TextXS, Badge }

export const Card = ({ type, card, mappings: customMappings, cardProps }) => {
  // create href as the combination of listing type + one of:
  // - slug if available
  // - id with underscores replaced with dashes
  const href = `/${type}/${
    card.og_slug ? card.og_slug[0]?.postgres_varchar : card.id
  }`

  // default mappings defining card structure for the parser down below
  // in the most simple case it requires prop to display
  // it also allows to choose and configure
  // the compoment to render the prop with
  const defaultMappings = {
    services: [
      { prop: "og_image" },
      {
        text: "Interactive",
        component: { type: "Kicker", props: { color: "primary.500" } },
      },
      { prop: "og_name", component: { type: "HeadingFive" } },
    ],
    blog: [
      { prop: "og_image" },
      {
        text: "Blog",
        component: { type: "TextXS", props: { color: "primary.500" } },
      },
      { prop: "og_name", component: { type: "HeadingFive" } },
      { prop: "og_author", component: { type: "TextSM" } },
    ],
    default: [
      { prop: "og_image" },
      { prop: "og_name", component: { type: "HeadingFive" } },
    ],
  }

  const mappings =
    customMappings || defaultMappings[type] || defaultMappings["default"]

  return (
    <Link
      key={card.id}
      href={href}
      variant="standalone"
      tabIndex={-1}
      display="block"
    >
      <Box data-cy="card-item" {...cardProps}>
        <Stack spacing={2}>
          {mappings.map(({ prop, text, component = {} }, i) => {
            const dataType = card[prop]?.[0]?.data_type?.id

            if (component.type === "Custom") {
              const Component = component.component
              return <Component key={i} {...card} />
            }

            // handle image case
            if (dataType === "og:IMAGE") {
              return card[prop][0]?.object ? (
                <CardImage key={i} src={card[prop][0]?.entity_meta?.local_id} />
              ) : null
            }

            // handle icon case
            if (component.type === "Icon") {
              const icon =
                card[prop][0]?.["postgres_varchar"] || card[prop][0]?.["object"]
              return <IconWrapper key={i} icon={icon} {...component.props} />
            }

            // handle Sparkline case
            if (component.type === "Sparkline") {
              return (
                <Fragment key={i}>
                  <TextXS>
                    {
                      component.meta?.sliceHasMeasure?.[0]?.entity_meta.name[0]
                        ?.i18n
                    }
                  </TextXS>
                  <Sparklines
                    data={
                      component.data
                        ?.find(d => d.id === card.id)
                        ?.data?.map(d => d.value) || []
                    }
                    width={200}
                    height={30}
                    min={0}
                    margin={0}
                  >
                    <SparklinesCurve
                      color={"blue"}
                      style={{ strokeWidth: 0.5 }}
                    />
                  </Sparklines>
                </Fragment>
              )
            }

            // render value with the specified component
            const Component = componentMap[component.type || "Text"]

            // reder static text
            if (text)
              return (
                <Box key={i}>
                  <Component {...component.props}>{text}</Component>
                </Box>
              )

            // collect displayed value
            // TODO: tap into data types to make it
            // smarter and more versatile
            const propertyValues = card[prop] || []
            return (
              <Box key={i}>
                {component.wrapSeparately ? (
                  propertyValues.map((d, i) => (
                    <Component key={i} {...component.props}>
                      {d?.i18n ||
                        d?.entity_meta?.name[0]?.i18n ||
                        d?.entity_meta?.local_id ||
                        d[d?.data_type?.postgresType]}
                    </Component>
                  ))
                ) : (
                  <Component {...component.props}>
                    {propertyValues
                      .map(d => {
                        return (
                          d?.i18n ||
                          d?.entity_meta?.name[0]?.i18n ||
                          d?.entity_meta?.local_id ||
                          d[d?.data_type?.postgresType]
                        )
                      })
                      .join(", ")}
                  </Component>
                )}
              </Box>
            )
          })}
        </Stack>
      </Box>
    </Link>
  )
}

Card.propTypes = {
  type: PropTypes.string,
  card: PropTypes.object,
  mappings: PropTypes.array,
  cardProps: PropTypes.object,
}
