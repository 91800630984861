import React from "react"
import PropTypes from "prop-types"

import { Stack } from "../../ui"

import { Header } from "./Header"
import { Listing as ListingComponent } from "./Listing"

export { Card } from "./Card"

export const Listing = ({
  gridVariant = "124",
  type,
  data = [],
  mappings,
  cardProps,
  header,
  sort,
  number,
  ...restProps
}) => {
  const newData = typeof sort === "function" ? data.sort(sort) : data
  const itemsToShow = number ? newData.slice(0, number) : newData
  const items = itemsToShow

  return (
    <Stack as="section">
      <Header {...header} />
      <ListingComponent
        gridVariant={gridVariant}
        items={items}
        type={type}
        mappings={mappings}
        cardProps={cardProps}
        {...restProps}
      />
    </Stack>
  )
}

Listing.propTypes = {
  type: PropTypes.string,
  data: PropTypes.array,
  mappings: PropTypes.array,
  cardProps: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  sort: PropTypes.func,
  number: PropTypes.number,
  viewMore: PropTypes.bool,
  spacingY: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  gridVariant: PropTypes.string,
}
