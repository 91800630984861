import React from "react"
import PropTypes from "prop-types"

import { Grid } from "../../ui/Grid"

import { Card } from "./Card"

export const Listing = ({
  gridVariant,
  type,
  mappings,
  cardProps,
  items,
  ...restProps
}) => {
  return (
    <Grid variant={gridVariant} {...restProps}>
      {items.map(data => (
        <Card
          key={data.id}
          type={type}
          card={data}
          mappings={mappings}
          cardProps={cardProps}
        />
      ))}
    </Grid>
  )
}

Listing.propTypes = {
  type: PropTypes.string,
  gridVariant: PropTypes.string,
  items: PropTypes.array,
  mappings: PropTypes.array,
  cardProps: PropTypes.object,
}
