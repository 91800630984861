import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "../../utils/i18n"
import { TextSM, TextXS, Stack, Link, ChevronRightIcon } from "../../ui"

export const Header = ({ title, seeAllHref, customHeader, ...restProps }) => {
  const { t } = useTranslation()
  if (!title && !seeAllHref && !customHeader) return null

  const HeaderContent = (
    <>
      <TextXS fontWeight={600} muted textTransform="uppercase">
        {title}
      </TextXS>
      {seeAllHref && (
        <Link href={seeAllHref} pl="xs">
          <Stack isInline alignItems="center" spacing="xxs">
            <TextSM as="span" muted>
              {t("og_c:featured_see-all")}
            </TextSM>
            <ChevronRightIcon boxSize="1.25rem" />
          </Stack>
        </Link>
      )}
    </>
  )

  return (
    <Stack
      isInline
      spacing={["sm", null, "1.5rem"]}
      justifyContent="space-between"
      alignItems="center"
      {...restProps}
    >
      {customHeader ? customHeader : HeaderContent}
    </Stack>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  seeAllHref: PropTypes.string,
  customHeader: PropTypes.element,
}
