import React from "react"
import dynamic from "next/dynamic"

/**
 * The IconWrapper component renders a specified icon from
 * `datastory-shared/ui/Icon`. It allows for dynamic icon rendering
 * and offers some code splitting benefits. Although the complete set
 * of icons is imported due to static analysis limitations, Next.js
 * may exclude them from the initial bundle if deemed unnecessary.
 */
export const IconWrapper = ({
  icon,
  fallback = "HelpCircleIcon",
  ...props
}) => {
  if (!icon) return null

  const Icon = dynamic(() =>
    import(`./index.js`).then(module => module[icon] || module[fallback])
  )

  return <Icon {...props} />
}
