import React from "react"
import { fetchEntities } from "datastory-shared/utils/hasura"

import { getPageProps } from "utils/ssg"

import Home from "components/pages/Home"

const Page = props => <Home {...props} />

export const getStaticProps = async ({ locale: language }) => {
  const services = await fetchEntities({
    classId: "og:Page",
    language,
    variables: {
      filter: {
        _and: [
          {
            triple_by_subject: {
              property: { _eq: "og:instanceOf" },
              object: { _eq: "og:Page" },
            },
          },
          {
            triple_by_subject: {
              property: { _eq: "og:pageCategory" },
              object: { _eq: "og:datastory-service" },
            },
          },
        ],
      },
      limit: 10,
      order_by: { "og:publishedAt": "desc" },
    },
    properties: [
      { id: "og:name" },
      { id: "og:description" },
      { id: "og:image" },
      { id: "og:slug" },
      { id: "og:publicationStatus" },
      { id: "og:publishedAt" },
    ],
  })

  const blogs = await fetchEntities({
    classId: "og:Page",
    language,
    variables: {
      filter: {
        _and: [
          {
            triple_by_subject: {
              property: { _eq: "og:instanceOf" },
              object: { _eq: "og:Page" },
            },
          },
          {
            triple_by_subject: {
              property: { _eq: "og:pageCategory" },
              object: { _eq: "og:datastory-blog" },
            },
          },
          {
            triple_by_subject: {
              property: { _eq: "og:cloudWebsite" },
              object: { _in: "og:datastory-org" },
            },
          },
        ],
      },
      limit: 3,
      order_by: { "og:publishedAt": "desc" },
    },

    properties: [
      { id: "og:name" },
      { id: "og:description" },
      { id: "og:image" },
      { id: "og:slug" },
      { id: "og:publishedAt" },
      { id: "og:publicationStatus" },
      { id: "og:author" },
    ],
  })

  return await getPageProps({
    language,
    namespaces: ["og_page--581211d4", "og_page--400127e6"],
    pageProps: { blogs, services },
    revalidate: 300,
  })
}

export default Page
