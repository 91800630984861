import React from "react"
import PropTypes from "prop-types"
import NextImage from "next/image"
import { useTheme } from "@chakra-ui/react"

export const getImageSrc = (src, baseUrl) => {
  const readySrcList = ["/", "http://", "https://", "blob:http", "data:image"]
  if (readySrcList.some(el => src.startsWith(el))) return src

  return `${baseUrl}/${src}`
}

export const Image = ({ src: initialSrc, ...rest }) => {
  const theme = useTheme()

  if (!initialSrc) return null

  const {
    customComponents: {
      image: { baseUrl },
    },
  } = theme
  const src = getImageSrc(initialSrc, baseUrl)

  return <NextImage src={src} {...rest} />
}

Image.propTypes = {
  src: PropTypes.string,
}
